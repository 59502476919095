define('admin/components/seller-creator/operator/component', ['exports', 'admin/validations/operator', 'ccon-common/mixins/saving'], function (exports, _operator, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_saving.default, {
    tagName: '',

    OperatorValidations: _operator.default,

    seller: null,
    operator: null,

    actions: {
      save: function save(changeset, operator) {
        return this.validateAndSave(changeset, operator);
      }
    }
  });
});