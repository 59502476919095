define('admin/components/cat-image-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      addImage: function addImage(cat, key) {
        Ember.get(cat, 'imageKeys').addObject(key);
        return cat.save();
      },
      removeImage: function removeImage(cat, key) {
        Ember.get(cat, 'imageKeys').removeObject(key);
      }
    }
  });
});