define('admin/helpers/is-descending', ['exports', 'ccon-common/helpers/is-descending'], function (exports, _isDescending) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isDescending.default;
    }
  });
  Object.defineProperty(exports, 'isDescending', {
    enumerable: true,
    get: function () {
      return _isDescending.isDescending;
    }
  });
});