define('admin/controllers/reset-password', ['exports', 'ccon-common/mixins/saving', 'ember-parachute', 'ccon-common/utils/extract-errors'], function (exports, _saving, _emberParachute, _extractErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.queryParams = undefined;
  var queryParams = exports.queryParams = new _emberParachute.default({
    token: {
      as: 't',
      defaultValue: null
    }
  });

  exports.default = Ember.Controller.extend(_saving.default, queryParams.Mixin, {
    notify: Ember.inject.service(),
    session: Ember.inject.service(),

    actions: {
      submit: function submit(changeset, reset) {
        var _this = this;

        var password = changeset.get('password');

        return this.validateAndSave(changeset, reset).then(function () {
          Ember.get(_this, 'notify').success("Password reset");

          var email = Ember.get(reset, 'email');
          var scope = 'seller';

          return Ember.get(_this, 'session').authenticate('authenticator:application', email, password, scope || undefined).then(function () {
            return _this.transitionToRoute('/');
          }).catch(function (reason) {
            if (reason.error) {
              (0, _extractErrors.default)([reason.error], changeset.addError.bind(changeset));
            }

            return Ember.RSVP.reject(reason);
          });
        });
      }
    }
  });
});