define('admin/controllers/stats', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var queryParams = new _emberParachute.default({
    size: {
      defaultValue: null
    },

    sellerId: {
      defaultValue: null
    },

    startDate: {
      defaultValue: null,

      serialize: function serialize(value) {
        return value ? moment(value).format('YYYY-MM-DD') : null;
      },
      deserialize: function deserialize(value) {
        return value ? moment(value).toDate() : null;
      }
    },

    endDate: {
      defaultValue: null,

      serialize: function serialize(value) {
        return value ? moment(value).format('YYYY-MM-DD') : null;
      },
      deserialize: function deserialize(value) {
        return value ? moment(value).toDate() : null;
      }
    },

    timeframe: {
      defaultValue: null
    }
  });

  exports.default = Ember.Controller.extend(queryParams.Mixin, {
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'startDate')) {
        Ember.set(this, 'startDate', moment().startOf('day').subtract('days', 7).utc().toDate());
      }

      if (!Ember.get(this, 'endDate')) {
        Ember.set(this, 'endDate', moment().startOf('day').utc().toDate());
      }

      Ember.set(this, 'size', 60 * 60);

      Ember.set(this, 'timeframe', 7);
    }
  });
});