define('admin/services/brands', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    active: null,

    load: function load() {
      var _this = this;

      return this.store.query('brand', {}).then(function (brands) {
        return Ember.get(brands, 'firstObject');
      }).then(function (brand) {
        Ember.set(_this, 'active', brand);
      });
    },
    reload: function reload() {
      return this.load();
    }
  });
});