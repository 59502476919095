define('admin/routes/carts/cart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('cart', params.id, { include: 'seller,location,inventory,boxes.latest-valuation,catalog,lines.collection', reload: true });
    }
  });
});