define('admin/components/profiles-dashboard/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortEnabled: false,

    actions: {
      useNewProfile: function useNewProfile() {
        Ember.set(this, 'profileToEdit', Ember.get(this, 'newProfile')());
      }
    }
  });
});