define('admin/models/box', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    location: belongsTo('location'),

    exportable: belongsTo('exportable', { polymorphic: true, inverse: 'boxes' }),

    lines: hasMany('boxLine'),

    code: attr('string'),
    pCode: Ember.computed('code', function () {
      return Ember.get(this, 'code') ? 'B' + Ember.get(this, 'code') : null;
    }),

    closed: attr('boolean'),
    exported: attr('boolean'),

    quantities: attr(),
    assumedQuantities: attr(),
    accountedQuantities: attr(),

    subtotal: attr('number'),
    accountedSubtotal: attr('number'),

    determinance: attr('boolean', { allowNull: true }),
    latestValuation: belongsTo('boxValuation'),

    closedAt: attr('date', { readOnly: true }),
    createdAt: attr('date', { readOnly: true })
  });
});