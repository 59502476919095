define('admin/routes/sellers/seller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('seller', params.id, { include: 'domain,primary-agent,secondary-agent', reload: true });
    }
  });
});