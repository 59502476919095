define('admin/controllers/lots', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var queryParams = new _emberParachute.default({
    keywords: {
      defaultValue: null
    }
  });

  exports.default = Ember.Controller.extend(queryParams.Mixin, {});
});