define('admin/components/seller-creator/invitation/component', ['exports', 'admin/validations/invitation', 'ccon-common/mixins/saving'], function (exports, _invitation, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_saving.default, {
    tagName: '',
    store: Ember.inject.service(),

    InvitationValidations: _invitation.default,

    seller: null,
    operator: null,
    invitation: null,

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'invitation', Ember.get(this, 'store').createRecord('invitation', { profile: Ember.get(this, 'operator') }));
    },


    actions: {
      save: function save(changeset, invitation) {
        return this.validateAndSave(changeset, invitation);
      }
    }
  });
});