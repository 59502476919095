define('admin/components/carts-dashboard/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    states: ['open', 'closed', 'shipped', 'accounted'],
    labels: {
      open: 'Open',
      closed: 'Closed',
      shipped: 'Shipped',
      accounted: 'Graded'
    },

    sorts: [['-created-at', 'Date'], ['total', 'Value']],
    month: new Date(),

    state: null,
    date: null,
    sort: null,

    stateHash: Ember.computed('state', function () {
      var state = Ember.get(this, 'state');

      if (state === 'accounted') {
        return { accounted: true };
      } else if (state === 'shipped') {
        return { shipped: true, accounted: false };
      } else if (state === 'closed') {
        return { closed: true, shipped: false };
      } else {
        return { closed: false };
      }
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'dateString')) {
        Ember.set(this, 'date', moment(Ember.get(this, 'dateString')).toDate());
      }
    },


    actions: {
      useDate: function useDate(val) {
        Ember.set(this, 'date', val);
        Ember.set(this, 'dateString', val ? val.toFormat('yyyy-MM-dd') : null);
      },
      useState: function useState(val) {
        this.useState(val);
      }
    }
  });
});