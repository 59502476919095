define('admin/routes/application', ['exports', 'ccon-common/mixins/application-route-mixin', 'ember-data'], function (exports, _applicationRouteMixin, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    rollbar: Ember.inject.service(),

    model: function model() {
      return Ember.get(this, 'brands').load();
    },
    _showError: function _showError(error) {
      return !(error instanceof _emberData.default.InvalidError) && error.message.indexOf('Failed to update a ServiceWorker') === -1;
    },


    actions: {
      error: function error(_error /*, transition */) {
        Ember.get(this, 'rollbar').error(_error);

        if (this._showError(_error)) {
          if (this.controller) {
            Ember.set(this.controller, 'uncaughtError', _error);
          } else {
            return true;
          }
        }
      }
    }
  });
});