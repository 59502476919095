define('admin/routes/authenticated', ['exports', 'ccon-common/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    socketGuru: Ember.inject.service(),

    afterModel: function afterModel() {
      var adapter = Ember.getOwner(this).lookup('adapter:application');
      var headers = adapter.getAuthHeaders();

      var sockets = Ember.get(this, 'socketGuru');
      var channels = {};
      channels['private-admin'] = ['recordCreated', 'recordUpdated', 'recordDeleted'];

      sockets.setupWithAuthHeaders(headers);
      sockets.addObservedChannels(channels);
    },


    actions: {
      addObject: function addObject(model, admin) {
        model.addObject(admin);
      }
    }
  });
});