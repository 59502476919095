define('admin/components/profiles-dashboard/user-editor/component', ['exports', 'admin/validations/user-lax'], function (exports, _userLax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    LaxUserValidations: _userLax.default,

    profile: null,

    store: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var profile = Ember.get(this, 'profile');

      Ember.get(profile, 'user').then(function (user) {
        Ember.set(_this, 'user', user || Ember.get(_this, 'store').createRecord('user'));
      });
    },


    actions: {
      save: function save(changeset, user) {
        Ember.set(user, 'profile', Ember.get(this, 'profile'));
        return this.save(changeset, user);
      }
    }
  });
});