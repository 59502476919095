define('admin/components/profiles-dashboard/profile-editor/employee/component', ['exports', 'admin/validations/employee', 'admin/validations/user'], function (exports, _employee, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    EmployeeValidations: _employee.default,
    UserValidations: _user.default
  });
});