define('admin/components/receipt-customizer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    cart: null,

    editable: false,
    exchanged: false,
    catalog: null
  });
});