define('admin/serializers/inventory-upload', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      data[Ember.String.underscore(Ember.String.decamelize(type.modelName))] = this.serialize(record, options);
    },
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    }
  });
});