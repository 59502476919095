define('admin/models/cart-line', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    cart: belongsTo('cart', { polymorphic: true }),
    collection: belongsTo('collection'),

    cat: belongsTo('cat'),
    catPrice: attr('number'),

    quantity: attr('number'),
    total: attr('number'),

    box: belongsTo('box'),

    createdAt: attr('date', { readOnly: true })
  });
});