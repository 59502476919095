define('admin/instance-initializers/errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var _application$containe = application.container,
        container = _application$containe === undefined ? application : _application$containe;

    container.lookup('service:errors');
  }

  exports.default = {
    initialize: initialize
  };
});