define('admin/models/invitation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    profile: belongsTo('profile'),
    email: attr('string'),

    createdAt: attr('date'),
    acceptedAt: attr('date'),
    revokedAt: attr('date'),
    expiredAt: attr('date'),

    key: attr('string', { readOnly: true })
  });
});