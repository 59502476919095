define('admin/models/consignment-line', ['exports', 'admin/models/portable-line', 'ember-data'], function (exports, _portableLine, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = _portableLine.default.extend({
    consignment: belongsTo('consignment'),

    determinance: attr('boolean', { allowNull: true }),
    cat: belongsTo('cat'),
    catPrice: attr('number'),

    quantity: attr('number'),

    total: attr('number'),
    value: attr('number')
  });
});