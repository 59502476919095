define('admin/models/exportable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    // grade: belongsTo('grade'), // causes some weird double-rendering bugs...
    seller: belongsTo('seller'),
    location: belongsTo('location'),

    export: belongsTo('export'),

    code: attr('string', { readOnly: true }),

    inventory: belongsTo('inventory'),
    catalog: belongsTo('catalog'),

    boxes: hasMany('box'),

    assumedQuantities: attr({ readOnly: true }),
    assumedSubtotal: attr('number', { readOnly: true }),

    accountedSubtotal: attr('number', { readOnly: true }),
    accountedQuantities: attr({ readOnly: true }),

    subtotal: attr('number', { readOnly: true }),

    gstAmount: attr('number', { readOnly: true }),
    total: attr('number', { readOnly: true }),

    isRemote: attr('boolean', { readOnly: true }),

    opened: attr('boolean'),
    closed: attr('boolean'),
    shipped: attr('boolean'),
    accounting: attr('boolean', { readOnly: true }),
    accounted: attr('boolean'),
    verified: attr('boolean'),
    disbursed: attr('boolean'),

    openedAt: attr('date', { readOnly: true }),
    closedAt: attr('date', { readOnly: true }),
    shippedAt: attr('date', { readOnly: true }),
    accountingAt: attr('date', { readOnly: true }),
    accountedAt: attr('date', { readOnly: true }),
    verifiedAt: attr('date', { readOnly: true }),
    disbursedAt: attr('date', { readOnly: true }),

    createdAt: attr('date', { readOnly: true }),
    updatedAt: attr('date', { readOnly: true })
  });
});