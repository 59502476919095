define('admin/models/operator', ['exports', 'admin/models/profile', 'ember-data'], function (exports, _profile, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _profile.default.extend({
    seller: belongsTo('seller'),
    ackedAccuracy: attr('boolean'),

    role: 'operator'
  });
});