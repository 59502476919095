define('admin/routes/inventories', ['exports', 'ember-socket-guru/mixins/socket-event-handler'], function (exports, _socketEventHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_socketEventHandler.default, {
    queryParams: {
      sort: { refreshModel: true }
    },

    infinity: Ember.inject.service(),

    model: function model(params) {
      return Ember.get(this, 'infinity').model('inventory', Ember.assign({}, { sort: params.sort }, {
        totalPagesParam: 'meta.page-count',
        countParam: 'meta.record-count'
      }));
    },


    socketActions: {
      recordCreated: function recordCreated(data) {
        var _this = this;

        var type = data.type,
            id = data.id;


        if (type === 'Inventory') {
          this.store.findRecord(type, id).then(function (inventory) {
            _this.modelFor(_this.routeName).unshiftObjects([inventory]);
          });
        }
      },
      recordUpdated: function recordUpdated(data) {
        var type = data.type,
            id = data.id;


        if (type === 'InventoryUpload' || type === 'CatImagesetUpload') {
          this.store.findRecord(type, id);
        }
      },
      recordDeleted: function recordDeleted(data) {
        var type = data.type,
            id = data.id;


        if (type === 'InventoryUpload' || type === 'CatImagesetUpload') {
          var record = this.store.peekRecord(type, id);

          if (record) {
            record.unloadRecord();
          }
        }
      }
    }
  });
});