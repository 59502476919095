define('admin/controllers/cats', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var queryParams = new _emberParachute.default({
    inventoryId: {
      defaultValue: '',
      refresh: true
    },

    keywords: {
      defaultValue: '',
      refresh: true
    },

    sort: {
      defaultValue: 'name',
      refresh: true
    },

    page: {
      defaultValue: 1,
      refresh: true
    }
  });

  exports.default = Ember.Controller.extend(queryParams.Mixin, {
    notify: Ember.inject.service(),

    actions: {
      saveCat: function saveCat(changeset, cat) {
        var _this = this;

        return changeset.validate().then(function () {
          if (Ember.get(changeset, 'isValid')) {
            return changeset.save().then(function (cat) {
              Ember.get(_this, 'notify').success("Cat saved");
              return cat;
            }).catch(function (reason) {
              Ember.get(cat, 'errors').forEach(function (_ref) {
                var attribute = _ref.attribute,
                    message = _ref.message;

                changeset.addError(attribute, message);
              });

              return Ember.RSVP.reject(reason);
            });
          } else {
            return Ember.RSVP.reject();
          }
        });
      }
    }
  });
});