define('admin/validations/inventory-upload', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    code: (0, _validators.validatePresence)(true),
    fileLocation: (0, _validators.validatePresence)(true)
  };
});