define('admin/models/invite', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    email: attr('string', { readOnly: true }),

    senderFullName: attr('string', { readOnly: true }),
    sellerName: attr('string', { readOnly: true }),
    sellerBrokerName: attr('string', { readOnly: true }),
    profileFullName: attr('string', { readOnly: true }),

    password: attr('string', { writeOnly: true }),
    termsAccepted: false
  });
});