define('admin/router', ['exports', 'admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('accept-invitation', { path: '/join/:invitation_token' });
    this.route('forgot-password', { path: '/forgot-password' });
    this.route('reset-password', { path: '/reset-password' });

    this.route('authentication', { path: '/signin' });
    this.route('authenticated', { path: '/' }, function () {
      this.route('admins', { resetNamespace: true });
      this.route('employees', { resetNamespace: true });
      this.route('sellers', { path: 'vendors', resetNamespace: true }, function () {
        this.route('seller', { path: '/:id' }, function () {
          this.route('operators', { path: '/' });
        });
      });

      this.route('agents', { resetNamespace: true }, function () {
        this.route('agent', { path: '/:agent_id' });
      });

      this.route('carts', { path: 'shipments', resetNamespace: true }, function () {
        this.route('cart', { path: '/:id' }, function () {
          this.route('operators', { path: '/' });
        });
      });

      this.route('lots', { resetNamespace: true }, function () {
        this.route('open');
        this.route('closed');
        this.route('shipped');

        this.route('lot', { path: '/:id' });
      });

      this.route('domains', { resetNamespace: true });

      this.route('cats', { resetNamespace: true });
      this.route('inventories', { resetNamespace: true });

      this.route('configs', { resetNamespace: true, path: '/config' });

      this.route('stats', { resetNamespace: true });
    });

    this.route('not-found', { path: '/*path' });
  });

  exports.default = Router;
});