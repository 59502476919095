define('admin/controllers/agents/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      transitionToAgent: function transitionToAgent(agent) {
        this.transitionToRoute('agents.agent', Ember.get(agent, 'id'));
      }
    }
  });
});