define('admin/components/profiles-dashboard/invitation-creator/component', ['exports', 'admin/validations/invitation'], function (exports, _invitation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    InvitationValidations: _invitation.default,

    profile: null,

    store: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'invitation', Ember.get(this, 'store').createRecord('invitation'));
    },


    actions: {
      save: function save(changeset, invitation) {
        var _this = this;

        Ember.set(invitation, 'profile', Ember.get(this, 'profile'));

        return this.save(changeset, invitation).then(function () {
          Ember.set(_this, 'invitation', Ember.get(_this, 'store').createRecord('invitation'));
        });
      }
    }
  });
});