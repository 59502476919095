define('admin/models/domain', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    name: attr('string'),
    verificationRecord: attr('string'),

    pointed: attr('boolean', { readOnly: true }),
    verified: attr('boolean', { readOnly: true }),
    likelyPropagated: attr('boolean', { readOnly: true }),

    staleExpiration: attr('date', { readOnly: true }),
    staleAutoRenew: attr('boolean', { readOnly: true }),

    seller: belongsTo('seller', { readOnly: true })
  });
});