define('admin/models/inventory-upload', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    code: attr('string'),
    fileLocation: attr('string', { writeOnly: true }),
    date: attr('date', { writeOnly: true }),

    hasFailure: attr('boolean'),
    failureMessage: attr('string', { readOnly: true }),

    createdAt: attr('date')
  });
});