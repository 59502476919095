define('admin/controllers/lots/shipped', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    lots: Ember.inject.controller(),
    keywords: Ember.computed.alias('lots.keywords')
  });
});