define('admin/components/seller-creator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    preview: function preview() {},
    escape: function escape() {},

    steps: ['seller', 'operator', 'invitation', 'complete'],
    step: 0,

    isLastStep: Ember.computed('step', function () {
      return Ember.get(this, 'step') === Ember.get(this, 'steps.length') - 1;
    }),

    subComponentName: Ember.computed('step', function () {
      return 'seller-creator/' + Ember.get(this, 'steps').objectAt(Ember.get(this, 'step'));
    }),

    seller: null,
    operator: null,

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'seller', Ember.get(this, 'store').createRecord('seller', {
        collectsGST: true
      }));

      Ember.set(this, 'operator', Ember.get(this, 'store').createRecord('operator', { seller: Ember.get(this, 'seller') }));
    },
    willDestroyElement: function willDestroyElement() {
      if (Ember.get(this, 'seller.isNew')) {
        Ember.get(this, 'seller').unloadRecord();
      }

      if (Ember.get(this, 'operator.isNew')) {
        Ember.get(this, 'operator').unloadRecord();
      }
    },


    actions: {
      abort: function abort() {
        var _this = this;

        if (Ember.get(this, 'seller.id')) {
          Ember.get(this, 'on-done')(Ember.get(this, 'seller')).then(function () {
            Ember.get(_this, 'escape')();
          });
        } else {
          Ember.get(this, 'escape')();
        }
      },
      next: function next() {
        if (Ember.get(this, 'isLastStep')) {
          Ember.get(this, 'on-done')(Ember.get(this, 'seller'));

          Ember.get(this, 'escape')();
        } else {
          this.incrementProperty('step');
        }
      }
    }
  }).reopenClass({
    positionalParams: []
  });
});