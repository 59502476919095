define('admin/components/sellers-dashboard/sponsorship-editor/component', ['exports', 'ccon-common/validations/sponsorship', 'ccon-common/mixins/saving'], function (exports, _sponsorship, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_saving.default, {
    tagName: '',

    SponsorshipValidations: _sponsorship.default,
    seller: null,
    operator: null,

    actions: {
      save: function save(changeset) {
        return this.validateAndSave(changeset);
      }
    }
  });
});