define('admin/controllers/sellers/index', ['exports', 'ccon-common/mixins/saving', 'ember-parachute'], function (exports, _saving, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var queryParams = new _emberParachute.default({
    sort: {
      defaultValue: 'name',
      refresh: true
    }
  });

  exports.default = Ember.Controller.extend(_saving.default, queryParams.Mixin, {
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    sorts: [['name', 'Name Ascending'], ['-name', 'Name Descending'], ['-created_at', 'Created']],

    queryParamsDidChange: function queryParamsDidChange(_ref) {
      var shouldRefresh = _ref.shouldRefresh,
          queryParams = _ref.queryParams;

      if (shouldRefresh) {
        this.transitionToRoute({ queryParams: queryParams });
      }
    },


    actions: {
      saveSeller: function saveSeller(changeset, seller) {
        return this.validateAndSave(changeset, seller);
      },
      goToSeller: function goToSeller(seller) {
        return this.transitionToRoute('sellers.seller', Ember.get(seller, 'id'));
      }
    }
  });
});