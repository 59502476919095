define('admin/controllers/carts/cart', ['exports', 'ccon-common/mixins/saving'], function (exports, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_saving.default, {
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    actions: {
      disburse: function disburse(cart) {
        var _this = this;

        Ember.set(cart, 'disbursed', true);

        return cart.save().then(function () {
          Ember.get(_this, 'notify').success("Shipment marked as paid");
        }, function (reason) {
          cart.rollbackAttributes();
          Ember.RSVP.rethrow(reason);
        });
      },
      generateReceipt: function generateReceipt(cart) {
        var editable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var exchanged = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var catalog = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        Ember.get(this, 'store').createRecord('receipt', { receivable: cart, editable: editable, exchanged: exchanged, catalog: catalog }).save().then(function (receipt) {
          window.open(Ember.get(receipt, 'url'));
        });
      }
    }
  });
});