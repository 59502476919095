define('admin/models/consignment', ['exports', 'admin/models/importable', 'ember-data'], function (exports, _importable, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;
  exports.default = _importable.default.extend({
    pCode: Ember.computed('code', function () {
      return Ember.get(this, 'code') ? 'SH' + Ember.get(this, 'code') : null;
    }),

    lines: hasMany('consignmentLine'),

    distributionCode: attr('string'),
    consignor: belongsTo('consignor'),

    assumedSubtotal: attr('number', { readOnly: true }),
    assumedQuantities: attr({ readOnly: true })
  });
});