define('admin/controllers/admins', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var queryParams = new _emberParachute.default({
    sort: {
      defaultValue: 'full-name',
      refresh: true
    }
  });

  exports.default = Ember.Controller.extend(queryParams.Mixin, {
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    sorts: [['full-name', 'Name']],

    queryParamsDidChange: function queryParamsDidChange(_ref) {
      var shouldRefresh = _ref.shouldRefresh,
          queryParams = _ref.queryParams;

      if (shouldRefresh) {
        this.transitionToRoute({ queryParams: queryParams });
      }
    },


    actions: {
      createNewAdmin: function createNewAdmin() {
        return Ember.get(this, 'store').createRecord('admin');
      },
      saveAdmin: function saveAdmin(changeset, admin) {
        var _this = this;

        return changeset.validate().then(function () {
          if (Ember.get(changeset, 'isValid')) {
            return changeset.save().then(function (admin) {
              Ember.get(_this, 'notify').success("Admin saved");
              return admin;
            }).catch(function (reason) {
              Ember.get(admin, 'errors').forEach(function (_ref2) {
                var attribute = _ref2.attribute,
                    message = _ref2.message;

                changeset.addError(attribute, message);
              });

              return Ember.RSVP.reject(reason);
            });
          } else {
            return Ember.RSVP.reject();
          }
        });
      },
      saveUser: function saveUser(changeset, user) {
        var _this2 = this;

        return changeset.validate().then(function () {
          if (Ember.get(changeset, 'isValid')) {
            return changeset.save().then(function (user) {
              Ember.get(_this2, 'notify').success("Login saved");
              return user;
            }).catch(function (reason) {
              Ember.get(user, 'errors').forEach(function (_ref3) {
                var attribute = _ref3.attribute,
                    message = _ref3.message;

                changeset.addError(attribute, message);
              });

              return Ember.RSVP.reject(reason);
            });
          } else {
            return Ember.RSVP.reject();
          }
        });
      },
      createInvitation: function createInvitation(changeset, invitation) {
        var _this3 = this;

        return changeset.validate().then(function () {
          if (Ember.get(changeset, 'isValid')) {
            return changeset.save().then(function (employee) {
              Ember.get(_this3, 'notify').success("Invitation created");
              return invitation;
            }).catch(function (reason) {
              Ember.get(invitation, 'errors').forEach(function (_ref4) {
                var attribute = _ref4.attribute,
                    message = _ref4.message;

                changeset.addError(attribute, message);
              });

              return Ember.RSVP.reject(reason);
            });
          } else {
            return Ember.RSVP.reject();
          }
        });
      }
    }
  });
});