define('admin/controllers/sellers/seller', ['exports', 'ccon-common/mixins/saving'], function (exports, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_saving.default, {
    notify: Ember.inject.service(),

    actions: {
      saveSeller: function saveSeller(changeset, seller) {
        return this.validateAndSave(changeset, seller);
      },
      enableSeller: function enableSeller(seller) {
        var _this = this;

        Ember.set(seller, 'isDisabled', false);

        return seller.save().then(function () {
          Ember.get(_this, 'notify').success("Vendor enabled");
        });
      },
      disableSeller: function disableSeller(seller) {
        var _this2 = this;

        Ember.set(seller, 'isDisabled', true);

        return seller.save().then(function () {
          Ember.get(_this2, 'notify').success("Vendor disabled");
        });
      },
      archiveSeller: function archiveSeller(seller) {
        var _this3 = this;

        Ember.set(seller, 'archived', true);

        return seller.save().then(function () {
          Ember.get(_this3, 'notify').success("Vendor deleted");
          _this3.transitionToRoute('sellers');
        });
      }
    }
  });
});