define('admin/controllers/forgot-password', ['exports', 'ccon-common/mixins/saving', 'ember-parachute'], function (exports, _saving, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.queryParams = undefined;
  var queryParams = exports.queryParams = new _emberParachute.default({
    email: {
      defaultValue: null
    }
  });

  exports.default = Ember.Controller.extend(_saving.default, queryParams.Mixin, {
    notify: Ember.inject.service(),

    actions: {
      submit: function submit(changeset, forget) {
        var _this = this;

        changeset.set('origin', window.location.origin);

        return this.validateAndSave(changeset, forget).then(function () {
          Ember.get(_this, 'notify').success("Password reset email sent");
        });
      }
    }
  });
});