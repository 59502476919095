define('admin/models/brand', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    name: attr('string'),
    label: attr('string'),

    logoUrl: attr('string'),

    themePrimaryColor: attr('string'),
    themeSecondaryColor: attr('string'),
    themeTertiaryColor: attr('string'),
    themeAccentColor: attr('string'),

    iconPrimaryColor: attr('string')
  });
});