define('admin/controllers/carts/index', ['exports', 'ccon-common/mixins/saving', 'ember-parachute'], function (exports, _saving, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var queryParams = new _emberParachute.default({
    sort: {
      defaultValue: '-created-at'
    },

    sellerId: {
      defaultValue: null
    },

    dateString: {
      as: 'date',
      defaultValue: null
    },

    state: {
      defaultValue: 'open'
    }
  });

  exports.default = Ember.Controller.extend(queryParams.Mixin, _saving.default, {
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    actions: {
      disburse: function disburse(cart) {
        var _this = this;

        Ember.set(cart, 'disbursed', true);

        return cart.save().then(function () {
          Ember.get(_this, 'notify').success("Shipment marked as paid");
        }, function (reason) {
          cart.rollbackAttributes();
          Ember.RSVP.rethrow(reason);
        });
      }
    }
  });
});