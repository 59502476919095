define('admin/serializers/employee', ['exports', 'admin/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      user: { serialize: false }
    }
  });
});