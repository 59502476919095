define('admin/routes/cats', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      if (params.inventoryId) {
        return this.store.findRecord('inventory', params.inventoryId);
      } else {
        return this.store.query('inventory', { filter: { active: true } }).then(function (inventories) {
          return _this.store.findRecord('inventory', Ember.get(inventories, 'firstObject.id'));
        });
      }
    }
  });
});