define('admin/components/inventory-creator/component', ['exports', 'admin/validations/inventory-upload'], function (exports, _inventoryUpload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    Validations: _inventoryUpload.default,

    store: Ember.inject.service(),
    session: Ember.inject.service(),

    minDate: new Date(),

    upload: null,

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'upload', Ember.get(this, 'store').createRecord('inventory-upload'));
    }
  });
});