define('admin/adapters/inventory-upload', ['exports', 'admin/config/environment', 'ember-data', 'ccon-common/mixins/data-adapter-mixin'], function (exports, _environment, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RESTAdapter = _emberData.default.RESTAdapter;
  exports.default = RESTAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.API_ORIGIN,
    namespace: 'uploads',

    pathForType: function pathForType() {
      return 'inventories';
    }
  });
});