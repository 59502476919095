define('admin/components/seller-creator/seller/component', ['exports', 'ccon-common/validations/seller', 'ccon-common/mixins/saving'], function (exports, _seller, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_saving.default, {
    tagName: '',

    SellerValidations: _seller.default,
    seller: null,
    operator: null,

    actions: {
      save: function save(changeset, seller) {
        return this.validateAndSave(changeset, seller).then(function (seller) {
          return seller.reload();
        });
      }
    }
  });
});