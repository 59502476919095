define('admin/components/cat-activity-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    activities: null,
    size: null,

    type: 'bubble',

    data: Ember.computed('activities', function () {
      if (Ember.get(this, 'activities')) {
        var sets = Ember.get(this, 'activities');

        return {
          datasets: sets.map(function (activities, i) {
            return {
              label: i === 0 && sets.length === 2 ? 'Selected vendor' : sets.length === 1 ? 'All vendors' : 'Other vendors',
              backgroundColor: i === 0 || sets.length === 1 ? 'rgba(20, 90, 205, 0.2)' : undefined,
              data: activities.map(function (activity) {
                return {
                  x: Ember.get(activity, 'interval'),
                  y: Ember.get(activity, 'count'),
                  r: 5 + Math.min(1, Ember.get(activity, 'avgCatPrice') * 5 / Ember.get(activity, 'inventoryMaxPrice')) * 45,

                  activity: activity
                };
              })
            };
          })
        };
      }
    }),

    options: Ember.computed(function () {
      var c = this;

      return {
        scales: {
          xAxes: [{
            type: 'time',
            distribution: 'series',
            ticks: {
              callback: function callback(value, index, values) {
                switch (Ember.get(c, 'size')) {
                  case 60 * 60:
                    return moment(values[index].value).format('MMM D ha');
                    break;

                  case 60 * 60 * 24:
                    return moment(values[index].value).format('MMM D');
                    break;

                  case 60 * 60 * 24 * 7:
                    return moment(values[index].value).format('[Week of] MMM D');
                    break;

                  case 60 * 60 * 24 * 7 * 30:
                    return moment(values[index].value).format('MMM');
                    break;

                  default:
                    return moment(values[index].value).format('LLL');
                    break;
                }
              }
            }
          }]
        },

        tooltips: {
          callbacks: {
            label: function label(tooltip, data) {
              var item = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              var catName = Ember.get(item.activity, 'cat.name');
              var catPrice = Ember.get(item.activity, 'avgCatPrice');

              var count = tooltip.yLabel;

              return catName + ' ($' + catPrice + '): ' + count;
            }
          }
        }
      };
    })
  }).reopenClass({
    positionalParams: ['activities']
  });
});