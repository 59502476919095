define('admin/adapters/brand', ['exports', 'ember-data', 'admin/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend({
    host: _environment.default.APP.API_ORIGIN,
    namespace: ''
  });
});