define('admin/components/sellers-dashboard/seller-editor/component', ['exports', 'ccon-common/validations/seller'], function (exports, _seller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    SellerValidations: _seller.default
  });
});