define('admin/components/s3-uploader/component', ['exports', 'admin/config/environment', 'fetch'], function (exports, _environment, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      uploadFile: function uploadFile(file) {
        var _this = this;

        var authData = Ember.get(this, 'authData');

        return (0, _fetch.default)(_environment.default.APP.API_ORIGIN + '/signatures/s3', {
          headers: {
            'Authorization': 'Bearer ' + authData.access_token + '; token_id=' + authData.token_id
          }
        }).then(function (response) {
          return response.json().then(function (data) {
            var url = data.url,
                credentials = data.credentials;


            return file.upload(url, {
              data: credentials
            }).then(function (response) {
              return Ember.get(_this, 'onUpload')(unescape(response.headers.location));
            });
          });
        });
      }
    }
  });
});