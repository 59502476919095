define('admin/components/sort-selector/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    tagName: '',

    isDescending: computed('key', function () {
      return get(this, 'key')[0] === '-';
    }),

    activeKey: computed('key', function () {
      return get(this, 'key') ? get(this, 'key').replace(/^\-/, '') : null;
    }),

    actions: {
      toggleDirection: function toggleDirection() {
        set(this, 'key', get(this, 'key')[0] === '-' ? get(this, 'key').replace(/^-/, '') : '-' + get(this, 'key'));
      }
    }
  });
});