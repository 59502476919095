define('admin/routes/price-lists', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      sort: { refreshModel: true },
      page: { refreshModel: true }
    },

    model: function model(params) {
      return this.store.query('price-list', { sort: params.sort, page: { number: params.page } });
    }
  });
});