define('admin/controllers/inventories', ['exports', 'ccon-common/mixins/saving'], function (exports, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_saving.default, {
    session: Ember.inject.service(),
    notify: Ember.inject.service(),

    actions: {
      uploadInventory: function uploadInventory(changeset, upload) {
        var _this = this;

        return this.validateAndSave(changeset, upload).then(function () {
          Ember.get(_this, 'notify').success("Upload initiated");
        });
      },
      deleteInventoryUpload: function deleteInventoryUpload(upload) {
        var _this2 = this;

        return upload.destroyRecord().then(function () {
          Ember.get(_this2, 'notify').success("Upload cancelled");
        });
      }
    }
  });
});