define('admin/routes/sellers/seller/operators', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      sort: { refreshModel: true }
    },

    infinity: Ember.inject.service(),

    model: function model(params) {
      var seller = this.modelFor('sellers.seller');
      var sellerId = Ember.get(seller, 'id');

      return Ember.RSVP.hash({
        seller: seller,
        operators: Ember.get(this, 'infinity').model('operator', { sort: params.sort, include: 'user', filter: { seller_id: sellerId } })
      });
    }
  });
});