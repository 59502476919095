define('admin/models/consignability', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    location: belongsTo('location'),

    name: attr('string'),

    consignor: belongsTo('consignor'),
    director: belongsTo('director'),

    defaultConsignorName: attr('string'),
    defaultConsignorPhone: attr('string'),

    defaultDirectorFullName: attr('string'),
    defaultDirectorEmail: attr('string'),

    defaultLocationName: attr('string'),

    autoInviteDirector: attr('boolean'),

    prefersExchanged: attr('boolean')
  });
});