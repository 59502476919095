define('admin/services/socket-guru', ['exports', 'admin/config/environment', 'ember-socket-guru/services/socket-guru'], function (exports, _environment, _socketGuru) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _socketGuru.default.extend({
    autoConnect: false,
    socketClient: 'pusher',

    config: {
      pusherKey: _environment.default.APP.PUSHER_KEY,
      cluster: _environment.default.APP.PUSHER_CLUSTER,
      encrypted: true,
      authEndpoint: _environment.default.APP.API_ORIGIN + '/signatures/pusher'
    },

    observedChannels: {
      'global': ['event']
    },

    setupWithAuthHeaders: function setupWithAuthHeaders(headers) {
      this.config.auth = { headers: headers };
      this.setup();
    }
  });
});