define('admin/routes/admins', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      sort: { refreshModel: true }
    },

    infinity: Ember.inject.service(),

    model: function model(params) {
      return Ember.get(this, 'infinity').model('admin', Ember.assign({}, { sort: params.sort }, { include: 'invitations,user' }, {
        totalPagesParam: 'meta.page-count',
        countParam: 'meta.record-count'
      }));
    }
  });
});