define('admin/models/statement-line', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    start: attr('date', { readOnly: true }),
    finish: attr('date', { readOnly: true }),

    primaryEarnings: attr('number', { readOnly: true }),
    secondaryEarnings: attr('number', { readOnly: true }),

    primaryCarts: hasMany('cart'),
    secondaryCarts: hasMany('cart'),

    isPayable: attr('boolean', { readOnly: true }),
    isPaid: attr('boolean')
  });
});